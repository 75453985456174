import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Prism from 'prismjs';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import BlogFooter from '../components/blog-footer';
import TagList from '../components/TagList';
import { breakpoints } from '../utils';

export const blogQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        publishedDate
        updatedDate
        tags
        coverImage
        imageAlt
        imageCredit
      }
      html
    }
    site {
      siteMetadata {
        author
        email
      }
    }
    file(relativePath: { eq: "me.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const BlogTitle = styled.h1`
  font-size: 1.8rem;

  @media ${breakpoints.small} {
    font-size: 2rem;
  }

  @media ${breakpoints.medium} {
    font-size: 2.2rem;
  }

  @media ${breakpoints.large} {
    font-size: 2.4rem;
  }
`;

const Blog = ({ data }) => {
  useEffect(() => {
    Prism.highlightAll();
  });
  const {
    title,
    publishedDate,
    updatedDate,
    coverImage,
    imageAlt,
    imageCredit,
    tags,
  } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <SEO title={title} />
      <BlogTitle>{title}</BlogTitle>
      <p style={{ fontStyle: 'italic' }}>Published {publishedDate}</p>
      {updatedDate && (
        <p style={{ fontStyle: 'italic' }}>Updated {updatedDate}</p>
      )}
      <img src={coverImage} alt={imageAlt} />
      <p>
        <em>{imageCredit}</em>
      </p>
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
      <hr />
      <TagList tags={tags} />
      <BlogFooter
        src={data.file.childImageSharp.fluid}
        author={data.site.siteMetadata.author}
        email={data.site.siteMetadata.email}
      />
    </Layout>
  );
};

export default Blog;
