import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { breakpoints } from '../utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${breakpoints.small} {
    padding: 40px 15px;
  }
`;

const ImgMe = styled(Img)`
  width: 100px;
  border-radius: 50%;
`;

const TextWrapper = styled.div`
  margin-bottom: 0;
  margin-left: 15px;
`;

const Title = styled.h3`
  margin-bottom: 10px;
  font-size: 1rem;
  @media ${breakpoints.small} {
    font-size: 1.4rem;
  }
`;

const Text = styled.p`
  font-size: 0.8rem;
  @media ${breakpoints.small} {
    font-size: 1rem;
  }
`;

const AuthorCard = ({ src, author, email }) => {
  return (
    <Wrapper>
      <ImgMe fluid={src} alt="Josiah's Face" />
      <TextWrapper>
        <Title>ABOUT THE AUTHOR</Title>
        <Text>
          This article was written by {author}.<br />
          Feel free to reach out at {email}
        </Text>
      </TextWrapper>
    </Wrapper>
  );
};

export default AuthorCard;
