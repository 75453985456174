import React from 'react';
import styled from 'styled-components';

import AuthorCard from '../components/author-card';

const Wrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const BlogFooter = ({ src, author, email }) => {
  return (
    <Wrapper>
      <AuthorCard src={src} author={author} email={email} />
    </Wrapper>
  );
};

export default BlogFooter;
